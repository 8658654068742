import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 99
    },
}));

const MyNavbar = (props) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar
                position="fixed"
            >
                <Toolbar position="static">
                    <Typography variant="h6" className={classes.title}>Buka Nota</Typography>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}
export default MyNavbar;

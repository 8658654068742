import React from "react";
import {
    Button, Dialog, DialogTitle, DialogActions, DialogContent
} from '@material-ui/core';

const DialogInfo = (props) => {
    const handleClose = () => {
        props.setOpen(false);
        if(props?.url){
            props.redirect(props.url)
        }else if(props?.refresh){
            props.refresh()
        }else{
            window.location.replace("/");
        }
    }
    return (
        <Dialog open={props.open}>
            <DialogTitle className="colorWhite">Perhatian</DialogTitle>
            <DialogContent className="colorWhite">
                    <span dangerouslySetInnerHTML={{ __html: props.text }}></span>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>Oke</Button>
            </DialogActions>
        </Dialog>
    )
}
export default DialogInfo;
import GQLPOS from '../GQL2';

const Order = {
  listMenu: (kodeoutlet) => {
    return new Promise((resolve, reject) => {
      const query = `
                    query{
                        listMenuCustOrder(kodeoutlet:"${kodeoutlet}"){
                          kodebarang
                          namabarang
                          kategori{
                              kodekategori
                              namakategori
                          }
                          subkategori{
                              kodesubkategori
                              namasubkategori
                          }
                          hargasatuan
                      }
                    }
                `
      GQLPOS(query).then(resp => {
        if (resp.errors) return (reject(resp.errors));;
        if (resp && resp.data && resp.data.listMenuCustOrder) {
          const listMenu = resp.data.listMenuCustOrder
          const listKategori = [];
          const kategoriSet = new Set();

          listMenu.forEach(item => {
            const kategori = item.kategori;
            if (!kategoriSet.has(kategori.kodekategori)) {
              kategoriSet.add(kategori.kodekategori);
              listKategori.push({
                kodekategori: kategori.kodekategori,
                namakategori: kategori.namakategori
              });
            }
          });
          return (resolve({ listMenu, listKategori }));
        } else {
          return (reject(new Error("Gagal load data")))
        }
      }).catch(err => reject(err));
    })
  },
  simpanOrderan: (kodeoutlet, idtrans, listOrder) => {
    return new Promise((resolve, reject) => {
      let listDataSave = "[";
      listOrder.map(el => {
        return (listDataSave += `{
          kodebarang: "${el.kodebarang}",
          namabarang: "${el.namabarang}",
          qty: ${el.qty},
          hargasatuan: ${el.hargasatuan},
          jumlah:  ${el.jumlah}}`);
      });
      listDataSave += "]";
      const query = `
                      mutation {
                        simpanOrderanCustomerOrder(kodeoutlet:"${kodeoutlet}", idtrans: "${idtrans}", listOrderan: ${listDataSave})
                    }
                `
      GQLPOS(query).then(resp => {
        if (resp.errors) return (reject(resp.errors));;
        if (resp && resp.data && resp.data.simpanOrderanCustomerOrder) {
          return resolve(true);
        } else {
          return (reject(new Error("Gagal load data")))
        }
      }).catch(err => reject(err));
    })
  },
}
export default Order
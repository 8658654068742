import React, { useState } from "react";
import {
    Toolbar, Typography, IconButton, AppBar, Container, Button, ButtonGroup
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Box from '@material-ui/core/Box';
import { Row, Col } from 'react-bootstrap';
import noImage from '../../Data/Image/noImage.png';
import Card from '@material-ui/core/Card';
import numberWithCommasEmpty from '../../Helper/numberWithCommasEmpty';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogInfo from '../../Dialog/DialogInfo';
import mOrder from '../../Controller/ordercust';



interface Props {
    outlet: {
        kodeoutlet: string;
        namaoutlet: string;
    } | null;
    idTrans: string;
    shoppingCart: ShoppingCartItem[];
    setIsOrder: (value: boolean) => void;
}

interface ShoppingCartItem {
    kodebarang: string;
    namabarang: string;
    gambar?: string;
    hargasatuan: number;
    kodesubkategori: number;
    qty: number;
    jumlah: number;
    catatan?: string;
}

const ModalOrder: React.FC<Props> = (props) => {
    const classes = useStyles();
    const { outlet, idTrans } = props;
    const [shoppingCart, setShoppingCart] = useState<ShoppingCartItem[]>(props.shoppingCart);
    const [isSimpan, setIsSimpan] = useState(false);
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState('');

    const handleBack = () => {
        props.setIsOrder(false);
    };
    console.log(shoppingCart)


    const handlePlus = (item: ShoppingCartItem) => {
        const newCart = shoppingCart.map(el => {
            if (el.kodebarang === item.kodebarang) {
                const qty = el.qty + 1;
                const jumlah = qty * el.hargasatuan;
                return {
                    ...el,
                    qty,
                    jumlah
                };
            }
            return el;
        });
        setShoppingCart(newCart);
    };

    const handleMinus = (item: ShoppingCartItem) => {
        if (item.qty === 1) {
            const newCart = shoppingCart.filter(el => el.kodebarang !== item.kodebarang);
            setShoppingCart(newCart);
        } else {
            const newCart = shoppingCart.map(el => {
                if (el.kodebarang === item.kodebarang) {
                    const qty = el.qty - 1;
                    const jumlah = qty * el.hargasatuan;
                    return {
                        ...el,
                        qty,
                        jumlah
                    };
                }
                return el;
            });
            setShoppingCart(newCart);
        }
    };



    const handleOrder = () => {
        setIsSimpan(true);
        mOrder.simpanOrderan(outlet?.kodeoutlet, idTrans, shoppingCart)
            .then(() => {
                setTextAlert("Orderan berhasil, silahkan infokan waiter");
                setIsSimpan(false);
                setIsAlert(true)
            })
            .catch(err => {
                alert(err[0].message)
            })
            .finally(() => setIsSimpan(false));
        //TODO
        setTimeout(() => {

        }, 1000);
    };

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleBack} aria-label="close">
                        <ArrowBackIcon />
                        <Typography variant="h6" className={classes.title}>Detail Orderan</Typography>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Container className="DialogFont bodyDialog">
                {isSimpan ? (
                    <h6 className="text-center">Sedang Proses Order <br />  <CircularProgress color="secondary" /></h6>
                ) : (
                    <Row>
                        <Col xs={12} className="mgTop5">
                            <Card className="cardOrderPay">
                                <Row className="width100">
                                    <Col xs={12}>
                                        <Box display="flex">
                                            <Box flexGrow={1} >
                                                <span className="headCart">Order Item(s)</span>
                                            </Box>
                                        </Box>
                                    </Col>

                                    {shoppingCart.map((item, i) => (
                                        <Col xs={12} key={i}>
                                            <Row>
                                                <Col xs={3}>
                                                    <img src={item.gambar || noImage} alt="Outlet" className="d-block mx-auto img-fluid w-100 pd10TB" />
                                                </Col>
                                                <Col xs={9}>
                                                    <Box display="flex">
                                                        <Box flexGrow={1} >
                                                            <span>{item.namabarang}</span><br />
                                                            <span>{numberWithCommasEmpty(item.hargasatuan)}</span><br />
                                                            {/* <ButtonGroup size="small" aria-label="small outlined button group">
                                                                <Button onClick={handleCatatan.bind(this, item)} color={(item.catatan || '').length > 0 ? "primary" : "default"}><CreateIcon style={{ height: 17 }} /></Button>
                                                            </ButtonGroup> */}
                                                        </Box>
                                                        <Box width={88}>
                                                            <span className="text-right">
                                                                <ButtonGroup size="small" aria-label="small outlined button group">
                                                                    <Button onClick={handleMinus.bind(this, item)}>-</Button>
                                                                    <Button disabled> {item.qty}</Button>
                                                                    <Button onClick={handlePlus.bind(this, item)}>+</Button>
                                                                </ButtonGroup>
                                                            </span>
                                                        </Box>
                                                    </Box>
                                                </Col>
                                            </Row>
                                        </Col>
                                    ))}
                                    <Col xs={12}>
                                        <Box display="flex">
                                            <Box flexGrow={1} >
                                                <small className="textColorprimary tangan" onClick={handleBack} >Add More Item(s)</small>
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={12} className="mgTop5">
                            <Card className="cardOrderPay">
                                <Row className="width100">
                                    <Col xs={12}>
                                        <Box display="flex">
                                            <Box flexGrow={1}><b>TOTAL:</b>
                                            </Box>
                                            <Box width={20}>
                                                <b className="text-right">{numberWithCommasEmpty(shoppingCart.map(el => el.jumlah).reduce((a, b) => a + b, 0))}</b>
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                )}
                <Row className="bgB60"></Row>
                <div className={classes.btnOrder}>
                    <Button disabled={isSimpan} style={{ marginTop: '5px' }} variant="contained" color="primary" fullWidth onClick={handleOrder}>Order </Button>
                </div>
                {isAlert && (
                    <DialogInfo open={isAlert} setOpen={setIsAlert} text={textAlert} />
                )}
            </Container>
        </>
    );
};

export default ModalOrder;

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
    btnOrder: {
        margin: theme.spacing(),
        position: "fixed",
        bottom: theme.spacing(1),
        zIndex: 2,
        width: window.innerWidth - 80
    },
}));

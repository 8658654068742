import React from 'react';
import { Row, Col } from 'react-bootstrap';


const Home = (props: any) => {
return (
    <Row>
        <Col>Welcome to Customer Order. Silahkan scan QR dari device anda</Col>
    </Row>
)
}
export default Home;
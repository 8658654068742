import React, { useEffect, useState } from 'react';
import RenderNota from './renderNota';
import TotalPoin from './totalPoin';
import Skeleton from 'react-loading-skeleton';


const jwt = require('jsonwebtoken');


const Order: React.FC<any> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [dataUrl, setDataUrl] = useState<any>({})
  useEffect(() => {
    if (props?.match?.params?.token) {
      try {
        const data = jwt.verify(props?.match?.params?.token, process.env.REACT_APP_JWT);
        if (data?.noinvoice && data?.kodeoutlet) {
          setDataUrl(data);
        }
      } catch (error) {
        console.log("Error generating JWT:", error);
      }
    }
    setIsLoading(false);
    return () => {
    }
  }, [props?.match?.params?.token]);

  return (
    <React.Fragment>
      {isLoading ? (
        <Skeleton height={150} />
      ) : (
        <>
          <RenderNota
            kodeoutlet={dataUrl.kodeoutlet}
            noinvoice={dataUrl.noinvoice}
          />
          <TotalPoin
            kodeoutlet={dataUrl.kodeoutlet}
            marginTop={10}
            noinvoice={dataUrl.noinvoice}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default Order;

import GQLPOS from "../GQL2";

const Invoice = {
  detailOutlet: (kodeoutlet: string) => {
    return new Promise((resolve, reject) => {
      const query = `
                    query{
                        publicDetailOutlet(kodeoutlet:"${kodeoutlet}"){
                          namaoutlet
                        phoneNumber
                        address
                      }
                    }
                `;
      GQLPOS(query)
        .then((resp) => {
          if (resp.errors) return reject(resp.errors);
          if (resp && resp.data && resp.data.publicDetailOutlet) {
            return resolve(resp.data.publicDetailOutlet);
          } else {
            return reject(new Error("Data Tidak Ada"));
          }
        })
        .catch((err) => reject(err));
    });
  },
  getFullNameKasir: (kodeoutlet: string, username: string) => {
    return new Promise((resolve, reject) => {
      const query = `
                    query{
                        publicGetFullNameKasir(kodeoutlet:"${kodeoutlet}", username: "${username}")
                    }
                `;
      GQLPOS(query)
        .then((resp) => {
          if (resp.errors) return reject(resp.errors);
          if (resp && resp.data && resp.data.publicGetFullNameKasir) {
            return resolve(resp.data.publicGetFullNameKasir);
          } else {
            return reject(new Error("Data Tidak Ada"));
          }
        })
        .catch((err) => reject(err));
    });
  },
  detailOrderan: (kodeoutlet: string, noinvoice: string) => {
    return new Promise((resolve, reject) => {
      const query = `
                    query{
                        publicDetailOrderan(kodeoutlet:"${kodeoutlet}", noinvoice: "${noinvoice}"){
                          _id
                          noinvoice
                          tanggal
                          nomeja
                          namacustomer
                          nomejaint
                          displayHarga
                          voidReason
                          noretur
                          sessionRetur
                          userRetur
                          jamretur
                          userin
                          userupt
                          jamin
                          jamupt
                          lastJamBayar
                          statusid
                          displayHarga
                          payment {
                              noinvoice
                              item {
                                  kodebarang
                                 namabarang
                                 qty
                                 harga
                                 jumlah
                                 discountAmount
                                 discountPercent
                             }
                             payment{
                                 kodepayment
                                 namapayment
                                 remark
                                 nomorkartu
                                 transRef
                                 amount
                             }
                              subtotal
                              discountAmount
                              discountPercent
                              serviceAmount
                              servicePercent
                              taxAmount
                              taxPercent
                              total
                              kembalian
                              pembulatan
                              totalPayment
                              userin
                              jamin
                              sessionId
                          }
                      }
                    }
                `;
      GQLPOS(query)
        .then((resp) => {
          if (resp.errors) return reject(resp.errors);
          if (resp && resp.data && resp.data.publicDetailOrderan) {
            return resolve(resp.data.publicDetailOrderan);
          } else {
            return reject(new Error("Data Tidak Ada"));
          }
        })
        .catch((err) => reject(err));
    });
  },
  totalPoin: (kodeoutlet: string, noinvoice: string) => {
    return new Promise((resolve, reject) => {
      const query = `
                    query{
                        publicTotalPoinCustomerByInv(kodeoutlet:"${kodeoutlet}", noinvoice: "${noinvoice}"){
                          kodecust
                          notrans
                          jumlah_poin
                          tanggal
                      }
                    }
                `;
      GQLPOS(query)
        .then((resp) => {
          if (resp.errors) return reject(resp.errors);
          if (resp && resp.data && resp.data.publicTotalPoinCustomerByInv) {
            return resolve(resp.data.publicTotalPoinCustomerByInv);
          } else {
            return reject(new Error("Data Tidak Ada"));
          }
        })
        .catch((err) => reject(err));
    });
  },
  detailPoin: (kodeoutlet: string, kodecust: string) => {
    return new Promise((resolve, reject) => {
      const query = `
                    query{
                        publicDetailMutasiPoinCustomer(kodeoutlet:"${kodeoutlet}", kodecust: "${kodecust}"){
                          notrans
                          tanggal
                          namabarang
                          jumlah_poin
                      }
                    }
                `;
      GQLPOS(query)
        .then((resp) => {
          if (resp.errors) return reject(resp.errors);
          if (resp && resp.data && resp.data.publicDetailMutasiPoinCustomer) {
            return resolve(resp.data.publicDetailMutasiPoinCustomer);
          } else {
            return reject(new Error("Data Tidak Ada"));
          }
        })
        .catch((err) => reject(err));
    });
  },
};
export default Invoice;

import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import RenderNota from './renderNota';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    dialogContent: {
      padding: theme.spacing(2),
      marginTop: 60
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface DetailPoinProps {
  open: boolean;
  onClose: () => void;
  kodeoutlet: string;
  notrans: string;
}

const DetailNota: React.FC<DetailPoinProps> = ({ open, onClose, notrans, kodeoutlet }) => {
  const classes = useStyles();


  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Detail Nota
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.dialogContent}>
      <RenderNota
        kodeoutlet={kodeoutlet}
        noinvoice={notrans}
      />
      </div>
    </Dialog>
  );
};

export default DetailNota;
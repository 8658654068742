import React, { useEffect, useState } from 'react';
import RenderNota from './renderNota';
import TotalPoin from './totalPoin';
import Skeleton from 'react-loading-skeleton';


const Order: React.FC<any> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [dataUrl, setDataUrl] = useState<any>({})
  useEffect(() => {
    if (props?.match?.params?.kodeoutlet && props?.match?.params?.idtrans) {
        setDataUrl({
            kodeoutlet: `outlet-${props?.match?.params?.kodeoutlet}`,
            noinvoice: `transaksi-${props?.match?.params?.idtrans}`,
        })
    }
    setIsLoading(false);
    return () => {
    }
  }, [props?.match?.params]);
console.log(dataUrl
    )
  return (
    <React.Fragment>
      {isLoading ? (
        <Skeleton height={150} />
      ) : (
        <>
          <RenderNota
            kodeoutlet={dataUrl.kodeoutlet}
            noinvoice={dataUrl.noinvoice}
          />
          <TotalPoin
            kodeoutlet={dataUrl.kodeoutlet}
            marginTop={10}
            noinvoice={dataUrl.noinvoice}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default Order;

import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Paper, Typography, Grid, Divider } from '@material-ui/core';
import moment from 'moment-timezone';
import mInv from '../../Controller/invoice';
import ModalDetailPoin from './ModalDetailPoin';

interface TotalPoinProps {
  kodecust: string;
  notrans: string;
  tanggal: string;
  jumlah_poin: number;
}
interface DataProps {
  kodeoutlet: string;
  marginTop: number;
  noinvoice: string;
}

const TotalPoin: React.FC<DataProps> = ({ kodeoutlet, marginTop, noinvoice }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [detailPoin, setDetailPoin] = useState<TotalPoinProps>({
    kodecust: '',
    notrans: '',
    tanggal: '',
    jumlah_poin: 0,
  })
  const [isOpenPoin, setIsOpenOpen] = useState(false);

  const handleOpenPoin = useCallback(() => {
    setIsOpenOpen(true);
  }, []);

  const handleClosePoin = useCallback(() => {
    setIsOpenOpen(false);
  }, []);


  useEffect(() => {
    if (isLoading) {
      mInv.totalPoin(kodeoutlet, noinvoice)
        .then((resp: any) => {
          if (resp?.totalPoin > 0) {
            setDetailPoin(resp);
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => setIsLoading(false));

    }
    return () => {

    }
  }, [isLoading, kodeoutlet, noinvoice]);




  const renderTotalPoin = useMemo(() => {
    return (
      <Paper style={{ padding: 20, maxWidth: 370, margin: '0 auto', marginTop: marginTop || 0 }}>
        <Typography variant="h6" align="center">
          Total Poin
        </Typography>
        <Divider style={{ margin: '10px 0' }} />
        <Grid container justify="space-between">
          <Typography variant="body2">Poin</Typography>
          <Typography variant="body2">
            {detailPoin.jumlah_poin.toLocaleString()}
          </Typography>
        </Grid>
        {detailPoin.notrans && (
          <Grid container justify="space-between">
            <Typography variant="body2">No Inv terakhir</Typography>
            <Typography variant="body2">
              {detailPoin.notrans}
            </Typography>
          </Grid>
        )}
        {detailPoin.tanggal && (
          <Grid container justify="space-between">
            <Typography variant="body2">Tanggal</Typography>
            <Typography variant="body2">
              {moment(detailPoin.tanggal).format("DD-MMM-YYYY")}
            </Typography>
          </Grid>
        )}
        <Typography variant="body2" align="center">
          <span style={{ fontSize: '0.8rem', color: "#005a9e", cursor: "pointer" }} onClick={handleOpenPoin}>
            Klik Lihat detail poin
          </span>
        </Typography>
        {isOpenPoin && (
          <ModalDetailPoin open={isOpenPoin} onClose={handleClosePoin} detailPoin={detailPoin} kodeoutlet={kodeoutlet} />
        )}
      </Paper>
    )
  }, [handleOpenPoin, handleClosePoin, isOpenPoin, detailPoin, marginTop, kodeoutlet])
  return renderTotalPoin;
};

export default TotalPoin;
export { };
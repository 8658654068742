import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Paper, Typography, Grid, Divider } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment-timezone';
import mInv from '../../Controller/invoice';

interface Outlet {
  namaoutlet: string;
  phoneNumber: string;
  address: string;
}

interface Item {
  kodebarang: string;
  namabarang: string;
  qty: number;
  harga: number;
  jumlah: number;
  discountAmount: number;
  discountPercent: number;
}

interface PaymentDetail {
  kodepayment: string;
  namapayment: string;
  remark: string | null;
  nomorkartu: string | null;
  transRef: string;
  amount: number;
}

interface Payment {
  noinvoice: string;
  item: Item[];
  payment: PaymentDetail[];
  subtotal: number;
  discountAmount: number;
  discountPercent: number;
  serviceAmount: number;
  servicePercent: number;
  taxAmount: number;
  taxPercent: number;
  total: number;
  kembalian: number;
  pembulatan: number;
  totalPayment: number;
  userin: string;
  jamin: string;
  sessionId: string;
}

interface Transaksi {
  _id: string;
  noinvoice: string;
  tanggal: string;
  nomeja: string;
  namacustomer: string;
  nomejaint: number;
  displayHarga: string;
  voidReason: string | null;
  noretur: string | null;
  sessionRetur: string | null;
  userRetur: string | null;
  jamretur: string | null;
  userin: string;
  userupt: string;
  jamin: string;
  jamupt: string;
  lastJamBayar: string;
  statusid: number;
  payment: Payment[];
}

interface DataProps {
  kodeoutlet: string;
  noinvoice: string
}


const RenderNota: React.FC<DataProps> = ({ kodeoutlet, noinvoice }) => {

  const [isLoadingOrderan, setIsLoadingOrderan] = useState(true)
  const [isLoadingOutlet, setIsLoadingOutlet] = useState(true);
  const [dataOutlet, setDataOutlet] = useState<Outlet | null>(null);
  const [dataOrderan, setDataOrderan] = useState<Transaksi | null>(null);
  const [fullNameKasir, setFullNameKasir] = useState<string>("")

  const loadFullNameKasir = useCallback((kodeoutlet: string, username: string) => {
    mInv.getFullNameKasir(kodeoutlet, username)
      .then((resp: Outlet | any) => {
        setFullNameKasir(resp);
      })
      .catch(err => {
        alert(err[0].message);
      });
  }, [setFullNameKasir]);

  const loadOutlet = useCallback((kodeoutlet: string) => {
    mInv.detailOutlet(kodeoutlet)
      .then((resp: Outlet | any) => {
        setDataOutlet(resp);
      })
      .catch(err => {
        alert(err[0].message);
      })
      .finally(() => setIsLoadingOutlet(false));
  }, [setDataOutlet, setIsLoadingOutlet]);

  const loadOrderan = useCallback((kodeoutlet: string, noinvoice: string) => {
    mInv.detailOrderan(kodeoutlet, noinvoice)
      .then((resp: Transaksi | any) => {
        setDataOrderan(resp);
        loadFullNameKasir(kodeoutlet, resp.userupt)
      })
      .catch(err => {
        alert(err[0].message);
      })
      .finally(() => setIsLoadingOrderan(false));
  }, [setDataOrderan, setIsLoadingOrderan, loadFullNameKasir]);

  useEffect(() => {
    if (kodeoutlet && noinvoice) {
      loadOutlet(kodeoutlet);
      loadOrderan(kodeoutlet, noinvoice);
    }
    return () => {

    }
  }, [kodeoutlet, noinvoice, loadOutlet, loadOrderan]);

  // const getLastPart = useCallback((invoice: string | null | undefined) => {
  //   if (invoice) {
  //     const lastPart = invoice.split('-')[1];
  //     const lastThreeDigits = lastPart.slice(-3);
  //     return lastThreeDigits.startsWith('0') ? lastThreeDigits.slice(1) : lastThreeDigits;
  //   } else {
  //     return null;
  //   }
  // }, []);

  const getDisplayHarga = useCallback((displayHarga: string | null | undefined) => {
    switch (displayHarga) {
      case 'harga1':
        return 'Dine In';
      case 'harga2':
        return 'Gojek';
      case 'harga3':
        return 'Grab';
      case 'harga4':
        return 'Shopee';
      case 'harga5':
        return 'Lainnya';
      default:
        return 'Unknown';
    }
  }, []);

  const renderNota = useMemo(() => (
    <Paper style={{ padding: 20, maxWidth: 370, margin: '0 auto' }}>
      <Typography variant="h6" align="center">
        {isLoadingOutlet ? <Skeleton width={50} /> : dataOutlet?.namaoutlet}
      </Typography>
      <Typography variant="body2" align="center">
        {isLoadingOutlet ? <Skeleton width={120} /> : dataOutlet?.address}
      </Typography>
      <Typography variant="body2" align="center">
        {isLoadingOutlet ? <Skeleton width={120} /> : dataOutlet?.phoneNumber}
      </Typography>
      <Divider style={{ margin: '10px 0' }} />
      <Grid container justify="space-between">
        <Typography variant="body2">No:</Typography>
        <Typography variant="body2">
          {isLoadingOrderan ? <Skeleton width={200} /> : dataOrderan?.noinvoice}
        </Typography>
      </Grid>
      <Grid container justify="space-between">
        <Typography variant="body2">Tgl:</Typography>
        <Typography variant="body2">
          {isLoadingOrderan ? <Skeleton width={200} /> : moment(dataOrderan?.lastJamBayar).format("DD-MMM-YYYY HH:mm:ss")}
        </Typography>
      </Grid>
      <Grid container justify="space-between">
        <Typography variant="body2">Meja:</Typography>
        <Typography variant="body2">
          {isLoadingOrderan ? <Skeleton width={20} /> : dataOrderan?.nomejaint}
        </Typography>
      </Grid>
      <Grid container justify="space-between">
        <Typography variant="body2">Tamu:</Typography>
        <Typography variant="body2">
          {isLoadingOrderan ? <Skeleton width={200} /> : dataOrderan?.namacustomer}
        </Typography>
      </Grid>
      <Grid container justify="space-between">
        <Typography variant="body2">Kasir:</Typography>
        <Typography variant="body2">
          {fullNameKasir.length === 0 ? <Skeleton width={50} /> : fullNameKasir}
        </Typography>
      </Grid>
      <Grid container justify="space-between">
        <Typography variant="body2">Transaksi:</Typography>
        <Typography variant="body2">
          {isLoadingOrderan ? <Skeleton width={200} /> : getDisplayHarga(dataOrderan?.displayHarga)}
        </Typography>
      </Grid>
      <Divider style={{ margin: '10px 0' }} />
      {dataOrderan?.payment?.map((payment, indexPay) => (
        <React.Fragment key={indexPay}>
          {payment.item.map((item, index) => (
            <Grid container justify="space-between" key={index}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {isLoadingOrderan ? <Skeleton width={100} /> : item?.namabarang}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" align="center">
                  {isLoadingOrderan ? <Skeleton width={50} /> : `${item.harga.toLocaleString()} x ${item.qty}`}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" align="right">
                  {isLoadingOrderan ? <Skeleton width={50} /> : (item.jumlah).toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
          ))}
          <Divider style={{ margin: '10px 0' }} />
          <Grid container justify="space-between">
            <Typography variant="body2">{payment?.item?.length} ITEMS</Typography>
          </Grid>
          <Grid container justify="space-between">
            <Typography variant="body2">Sub Total</Typography>
            <Typography variant="body2">
              {isLoadingOrderan ? <Skeleton width={50} /> : payment.subtotal.toLocaleString()}
            </Typography>
          </Grid>
          <Grid container justify="space-between">
            <Typography variant="body2">Tax</Typography>
            <Typography variant="body2">
              {isLoadingOrderan ? <Skeleton width={50} /> : payment.taxAmount.toLocaleString()}
            </Typography>
          </Grid>
          {payment.discountAmount > 0 && (
            <Grid container justify="space-between">
              <Typography variant="body2">Discount</Typography>
              <Typography variant="body2">
                {isLoadingOrderan ? <Skeleton width={50} /> : payment.discountAmount.toLocaleString()}
              </Typography>
            </Grid>
          )}
          {payment.pembulatan > 0 && (
            <Grid container justify="space-between">
              <Typography variant="body2">Pembulatan</Typography>
              <Typography variant="body2">
                {isLoadingOrderan ? <Skeleton width={50} /> : payment.pembulatan.toLocaleString()}
              </Typography>
            </Grid>
          )}
          <Grid container justify="space-between">
            <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Total</Typography>
            <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
              {isLoadingOrderan ? <Skeleton width={50} /> : payment.total.toLocaleString()}
            </Typography>
          </Grid>
          {payment?.payment?.map((pay, indexP) => (
            <Grid container justify="space-between" key={indexP}>
              <Typography variant="body2">{pay.namapayment}</Typography>
              <Typography variant="body2">
                {isLoadingOrderan ? <Skeleton width={50} /> : pay.amount.toLocaleString()}
              </Typography>
            </Grid>
          ))}
          <Grid container justify="space-between">
            <Typography variant="body2">Kembali</Typography>
            <Typography variant="body2">
              {isLoadingOrderan ? <Skeleton width={50} /> : payment.kembalian === 0 ? '-' : payment.kembalian.toLocaleString()}
            </Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Paper>
  ), [isLoadingOrderan, isLoadingOutlet, dataOrderan, dataOutlet, getDisplayHarga, fullNameKasir]);


  return renderNota;
};

export default RenderNota;

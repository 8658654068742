import React, { useEffect, useReducer, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthContext from './context/auth-context';
import { useCookies } from 'react-cookie';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from './Navbar/Navbar'
import GQL from './GQL'
import DialogInfo from './Dialog/DialogInfo'
import Home from './Components/Home';
import Order from './Components/Order';
import Invoice from './Components/Invoice';
import InvoiceIDTrans from './Components/Invoice/index_idtrans';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    width: window.innerWidth - 200
  },
}));

const initialValue = {
  token: null,
  clientID: null,
  clientID2: null,
  userLogin: null,
  hp: null,
  email: null,
  isCookieLoad: false,
  isLogin: false
}

const loginReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        token: action.token,
        clientID: action.clientID,
        clientID2: action.clientID2,
        userLogin: action.userLogin,
        email: action.email,
        hp: action.hp,
        isLogin: true
      }
    case "LOGOUT":
      return {
        ...state,
        userLogin: null,
        token: null,
        clientID: null,
        clientID2: null,
        email: null,
        hp: null
      }
    case "COOKIE":
      return {
        ...state,
        token: action.token,
        clientID: action.clientID,
        clientID2: action.clientID2,
        userLogin: action.userLogin,
        email: action.email,
        hp: action.hp,
        isCookieLoad: true
      }
    default: throw new Error("Invalid Action");
  }
}

function App(props) {
  const [state, dispatch] = useReducer(loginReducer, initialValue)
  const [cookies, setCookie, removeCookie] = useCookies([`${process.env.REACT_APP_COMPANY}-delivery`]);
  const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
  const [dialogtext, setDialogtext] = useState()
  const classes = useStyles();
  useEffect(() => {
    var render = true;
    if (cookies[`${process.env.REACT_APP_COMPANY}-delivery-auth`]) {
      const { clientID, clientID2, token, userLogin, email, hp } = cookies[`${process.env.REACT_APP_COMPANY}-delivery-auth`] || { clientID: null, clientID2: null, token: null, userLogin: null, email: null, hp: null };
      if (render) {
        //console.log(clientID,token);
        dispatch({ type: "COOKIE", clientID, clientID2, token, userLogin, email, hp })
      }
    } else {
      dispatch({ type: "COOKIE", clientID, clientID2, token })

    }
    return () => {
      render = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies[`${process.env.REACT_APP_COMPANY}-delivery-auth`]]);

  const login = (username, password) => {
    const userLogin = username
    const query = `
                        mutation{
                            loginweb(input:{username:"${username}",password:"${password}"}){
                                clientID
                                token
                                success
                                info
                                email
                                hp
                                clientID2
                            }
                        }
                        `
    GQL(query).then(res => {
      if (res.errors) throw res.errors;
      if (res.data.loginweb.success) {
        const token = res.data.loginweb.token
        const clientID = res.data.loginweb.clientID
        const clientID2 = res.data.loginweb.clientID2
        const email = res.data.loginweb.email
        const hp = res.data.loginweb.hp
        setCookie(`${process.env.REACT_APP_COMPANY}-delivery-auth`, { clientID, clientID2, token, userLogin, email, hp }, { maxAge: 30 * 24 * 60 * 60, path: '/' })
        dispatch({ type: "LOGIN", clientID, clientID2, token, userLogin, email, hp })
      } else {
        setOpenConfirmInfo(true)
        setDialogtext(`<p>${res.data.loginweb.info}<p/>`)
      }
    }).catch(err => {
      if (err && err[0].message) {
        setOpenConfirmInfo(true)
        setDialogtext(`<p>${err[0].message}<p/>`)
      } else {
        setDialogtext(`<p>Tidak dapat menjalankan permintaan Anda saat ini. Mohon coba lagi nanti.<p/>`)
      }
    })
  }

  const logout = () => {
    const query = `
                mutation {
                  logoutUser
                }
            `
    GQL(query, state.clientID, state.token).then(resp => {
      if (resp.errors) throw resp.errors;
      if (resp.data && resp.data.logoutUser) {
      } else {
        setOpenConfirmInfo(true)
        setDialogtext(`<p>Gagal Logout<p/>`)
      }
    }).catch(err => {
      console.log(err)
      if (err[0].message) {
        setOpenConfirmInfo(true)
        setDialogtext(`<p>${err[0].message}<p/>`)
      } else {
        setDialogtext(`<p>Tidak dapat menjalankan permintaan Anda saat ini. Mohon coba lagi nanti.<p/>`)
      }
    }).finally(() => {
      const clientID = null, clientID2 = null, token = null, userLogin = null, email = null, hp = null
      setCookie(`${process.env.REACT_APP_COMPANY}-delivery-auth`, { clientID, clientID2, token, userLogin, email, hp }, { maxAge: 30 * 24 * 60 * 60, path: '/' })
      removeCookie(`${process.env.REACT_APP_COMPANY}-delivery-auth`)
      dispatch({ type: "LOGOUT" })
    })
  }
  //isCookieLoad
  const { clientID, clientID2, token, isLogin, userLogin, email, hp } = state;

  //console.log(state)
  return (
    <Router>
      <React.Fragment>
        <AuthContext.Provider
          value={{
            token,
            clientID,
            clientID2,
            login,
            logout,
            userLogin,
            email,
            hp
          }}
        >
          <div className={classes.root} >
            <CssBaseline />
            <Navbar onLogout={logout} isLogin={(token && clientID)} pathname={window.location.pathname} />
            <main className={classes.content}>
              <Switch>
                <React.Fragment>
                  <Route path="/" exact component={Home} />
                  <Route path="/home" exact component={Home} />
                  <Route path="/order/:token" exact component={Order} />
                  <Route path="/invoice/:token" exact component={Invoice} />
                  <Route path="/invoice/:kodeoutlet/:idtrans" exact component={InvoiceIDTrans} />


                  {isLogin && <Redirect from="/home/" to="/" exact />}
                </React.Fragment>

              </Switch>
            </main>
          </div>
        </AuthContext.Provider>
        <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} />
      </React.Fragment>

    </Router>
  );
}

export default App;

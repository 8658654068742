import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import Skeleton from 'react-loading-skeleton';
import mInv from '../../Controller/invoice';
import ModalDetailNota from './ModalDetailNota';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    table: {
      minWidth: 650,
    },
    tableContainer: {
      padding: theme.spacing(2),
    },
    dialogContent: {
      padding: theme.spacing(2),
      marginTop: 60
    },
    tableHeader: {
      backgroundColor: '#f5f5f5',
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface TotalPoinProps {
  kodecust: string;
  notrans: string;
  tanggal: string;
  jumlah_poin: number;
}
interface DetailPoinProps {
  open: boolean;
  onClose: () => void;
  detailPoin: TotalPoinProps;
  kodeoutlet: string
}

const DetailPoin: React.FC<DetailPoinProps> = ({ open, onClose, detailPoin, kodeoutlet }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listData, setListData] = useState<any[]>([]);
  const [isOPenDetailNota, setIsOPenDetailNota] = useState<boolean>(false);
  const [notrans, setNotrans] = useState<string>("")

  useEffect(() => {
    if (isLoading && kodeoutlet && detailPoin.kodecust) {
      mInv.detailPoin(kodeoutlet, detailPoin.kodecust)
        .then((resp: any) => {
          setListData(resp);
        })
        .catch(err => {
          alert(err[0].message);
        })
        .finally(() => setIsLoading(false));

    }else if(!detailPoin?.kodecust){
      setIsLoading(false)
    }
    return () => {

    }
  }, [isLoading, kodeoutlet, detailPoin]);
  let balance = 0;
  const handleCloseNota = useCallback(() => {
    setIsOPenDetailNota(false);
  }, []);

  const handleOpenNota = useCallback((notransInput: string) => {
    setNotrans(notransInput);
    setIsOPenDetailNota(true);
  }, [])

  const renderDetailNota = useMemo(() => {
    if (isOPenDetailNota) {
      return <ModalDetailNota open={isOPenDetailNota} onClose={handleCloseNota} kodeoutlet={kodeoutlet} notrans={notrans} />;
    }
    return null;
  }, [isOPenDetailNota, handleCloseNota, kodeoutlet, notrans]);
  
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Detail Poin
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.dialogContent}>
        {isLoading ? (
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} size="small" aria-label="simple table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell><Skeleton width={50} /></TableCell>
                  <TableCell><Skeleton width={100} /></TableCell>
                  <TableCell><Skeleton width={100} /></TableCell>
                  <TableCell><Skeleton width={150} /></TableCell>
                  <TableCell><Skeleton width={50} /></TableCell>
                  <TableCell><Skeleton width={50} /></TableCell>
                  <TableCell><Skeleton width={70} /></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[...Array(5)].map((_, index) => (
                  <TableRow key={index}>
                    <TableCell><Skeleton width={50} /></TableCell>
                    <TableCell><Skeleton width={100} /></TableCell>
                    <TableCell><Skeleton width={100} /></TableCell>
                    <TableCell><Skeleton width={150} /></TableCell>
                    <TableCell><Skeleton width={50} /></TableCell>
                    <TableCell><Skeleton width={50} /></TableCell>
                    <TableCell><Skeleton width={70} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} size="small" aria-label="simple table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Notrans</TableCell>
                  <TableCell>Tanggal</TableCell>
                  <TableCell>IN</TableCell>
                  <TableCell>Out</TableCell>
                  <TableCell>Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listData.map((row, index) => {
                  balance += row.jumlah_poin;
                  return (
                    (
                      <TableRow key={index}>
                        <TableCell>{1 + index++}</TableCell>
                        <TableCell
                          onClick={row.notrans.startsWith('PAYMENTPOIN-') ? undefined : () => handleOpenNota(row.notrans)}
                          style={
                            row.notrans.startsWith('PAYMENTPOIN-')
                              ? {}
                              : { color: '#005a9e', cursor: 'pointer' }
                          }
                        >
                          {row.notrans}
                        </TableCell>
                        <TableCell>{row.tanggal}</TableCell>
                        <TableCell>{row.jumlah_poin > 0 ? row.jumlah_poin : 0}</TableCell>
                        <TableCell>{row.jumlah_poin < 0 ? row.jumlah_poin * -1 : 0}</TableCell>
                        <TableCell>{balance}</TableCell>
                      </TableRow>
                    )
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {renderDetailNota}
      </div>
    </Dialog>
  );
};

export default DetailPoin;
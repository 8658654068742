import React, { useEffect, useState, useMemo, useCallback } from 'react';
import numberWithCommasEmpty from '../../Helper/numberWithCommasEmpty';
import {
  Container,
  Box,
} from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import noImage from '../../Data/Image/noImage.png';
import Skeleton from 'react-loading-skeleton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { makeStyles } from '@material-ui/core/styles';
import mOrder from '../../Controller/ordercust';
import ModalDetail from './ModalDetail'



const jwt = require('jsonwebtoken');

type HomeProps = {
  history: {
    push(url: string): void;
  },
  match: {
    params: {
      token: string
    }
  }
}

type Outlet = {
  kodeoutlet: string;
  namaoutlet: string;
}

const Order = (props: HomeProps) => {
  const classes = useStyles();
  const [outlet, setOutlet] = useState<Outlet | null>(null);
  const [idTrans, setIdTrans] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listMenu, setListMenu] = useState<any[]>([]);
  const [listKategori, setListKategori] = useState<any[]>([]);
  const [shoppingCart, setShoppingCart] = useState<any[]>([]);
  const rowLoad = [0, 1, 2, 3, 4, 5];
  const [isOrder, setIsOrder] = useState(false)



  const loadMenu = useCallback((kodeoutlet: string) => {
    setIsLoading(true);
    mOrder.listMenu(kodeoutlet)
      .then((res) => {
        console.log(res)
        setListMenu(res.listMenu)
        setListKategori(res.listKategori)
      })
      .catch(err => {
        alert(err[0].message)
      })
      .finally(() => setIsLoading(false))
  }, []);

  const loadOrderan = useCallback((kodeoutlet: string, idTrans: string) => {

  }, []);

  useEffect(() => {
    if (props?.match?.params?.token) {
      try {

        const data = jwt.verify(props?.match?.params?.token, process.env.REACT_APP_JWT);
        if (data?.idtransaksi) setIdTrans(data.idtransaksi);
        if (data?.kodeoutlet) {
          setOutlet({
            kodeoutlet: data.kodeoutlet,
            namaoutlet: data.namaoutlet,
          })
        }
      } catch (error) {
        console.log("Error generating JWT:", error);
      }
    }
    return () => {

    }
  }, [props?.match?.params?.token]);

  useEffect(() => {
    if (outlet?.kodeoutlet && idTrans) {
      loadMenu(outlet?.kodeoutlet);
      loadOrderan(outlet?.kodeoutlet, idTrans);
    }

    return () => {

    }
  }, [outlet, idTrans, loadMenu, loadOrderan])

  const handleItemClick = useCallback((item: any) => {
    const newCart: any[] = []
    const listCart = [...shoppingCart, { ...item, qty: 1 }]
    listCart.reduce((res, val) => {
      if (!res[val.kodebarang]) {
        res[val.kodebarang] = { 
          kodebarang: val.kodebarang,
          namabarang: val.namabarang,
          qty: 0,
          hargasatuan: val.hargasatuan,
          jumlah: 0,
          gambar: val?.gambar,
          manajemenPrinter: val?.manajemenPrinter,
          kodesubkategori: val?.subkategori?.kodesubkategori,
         }
        newCart.push(res[val.kodebarang])
      }
      res[val.kodebarang].qty += val.qty
      res[val.kodebarang].jumlah = res[val.kodebarang].qty * res[val.kodebarang].hargasatuan
      return res
    }, {})
    setShoppingCart(newCart)
  }, [shoppingCart]);
  
  const renderDetailOrderan = useMemo(() => {
    if (isOrder) {
      return <ModalDetail outlet={outlet} idTrans={idTrans} shoppingCart={shoppingCart} setIsOrder={setIsOrder} />;
    } else return null;
  }, [isOrder, outlet, idTrans, shoppingCart, setIsOrder]);
  const renderLayout = useMemo(() => {
    if (!isOrder) {
      return (
        <React.Fragment>
          <Container className="DialogFont bodyDialog">
            <h5 className="text-center">{outlet?.namaoutlet}</h5>
            {listKategori.map((k, indexK) => (
              <div key={indexK}>
                <Row><Col> <h5 className="textColorprimary">{k.namakategori}</h5></Col></Row>
                {listMenu.filter(b => b?.kategori?.kodekategori === k.kodekategori).map((b, indexB) => (
                  <Row key={indexB} onClick={handleItemClick.bind(this, b)} className="tangan">
                    <Col xs={4}>
                      <img src={b.gambar || noImage} alt="Outlet" className="d-block mx-auto img-fluid imgMenu" />
                    </Col>
                    <Col xs={8}>
                      <Box display="flex" p={1} style={{ marginTop: 30 }}>
                        <Box p={1} flexGrow={1} >{b.namabarang}</Box>
                        <Box p={1} >
                          {numberWithCommasEmpty(b.hargasatuan)}
                        </Box>
                      </Box>
                    </Col>
                    <Col className="border-btm"></Col>
                  </Row>
                ))}
              </div>
            ))}
            {isLoading && rowLoad.map((el, i) => (
              <Row key={i}>
                <Col xs={4}>
                  <Skeleton height={65} />
                </Col>
                <Col xs={8}>
                  <Box display="flex" p={1} style={{ marginTop: 12 }}>
                    <Box p={1} flexGrow={1} > <Skeleton width={100} /></Box>
                    <Box p={1} >
                      <Skeleton width={40} />
                    </Box>
                  </Box>
                </Col>
                <Col className="border-btm"></Col>
              </Row>
            ))}
            {!isLoading && (listMenu.length <= 0 || listKategori.length <= 0) && (
              <Col xs={12} className="mgTop5">
                <Col xs={12}>
                  <h6 className="text-center">Belum ada list menu yang di tampilkan</h6>
                </Col>
              </Col>
            )}
            <Row className="bgB60"></Row>
          </Container>
          <div >
            <div className={classes.btnOrder} onClick={() => setIsOrder(true)}>
              <Box display="flex" p={1} className="btn-card">
                <Box p={1} flexGrow={1} >
                  {shoppingCart.length} Item | {numberWithCommasEmpty(shoppingCart.map(el => el.jumlah).reduce((a, b) => a + b, 0))}
                </Box>
                <Box p={1} >
                  <ShoppingCartIcon />
                </Box>
              </Box>
            </div>
          </div>

        </React.Fragment>
      )
    } else return null;
  }, [outlet?.namaoutlet, isLoading, classes.btnOrder, rowLoad, listMenu, listKategori, handleItemClick, shoppingCart, isOrder]);
  return (
    <React.Fragment>
      {renderLayout}
      {renderDetailOrderan}
    </React.Fragment>
  )
}

export default Order;

const useStyles = makeStyles(theme => ({
  btnOrder: {
    margin: theme.spacing(),
    position: "fixed",
    bottom: theme.spacing(1),
    zIndex: 2,
    width: window.innerWidth - 50,
    cursor: "pointer"
  },
}));